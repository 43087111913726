/**
* @name irish-life.less
* @desc less file for irish-life theme styles
*/
/* Color Palette */
/*
	Irish Life Theme Overrides
*/
.cx-widget.cx-theme-irish-life {
  color: #ffffff;
  background-color: #5261ac;
  scrollbar-face-color: #a3a8ae;
  scrollbar-track-color: #212529;
  scrollbar-arrow-color: #a3a8ae;
  /* WCAG: Non-text contrast for ui components - state changes to focus and hover */
}
.cx-widget.cx-theme-irish-life .cx-theme {
  background-color: #5261ac;
  color: #ffffff;
  border-color: #5261ac !important;
}
.cx-widget.cx-theme-irish-life .cx-svg-icon-tone1 {
  fill: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-svg-icon-tone2 {
  fill: #8c8c8c;
}
.cx-widget.cx-theme-irish-life .cx-svg-icon-shadow1 {
  fill: #000000;
  opacity: 0.2;
}
.cx-widget.cx-theme-irish-life .cx-svg-icon-shadow2 {
  fill: #000000;
  opacity: 0.1;
}
.cx-widget.cx-theme-irish-life * {
  border-color: #5261ac;
}
.cx-widget.cx-theme-irish-life .cx-buttons-window-control .cx-svg-icon-tone1 {
  fill: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-input-icon-overlay .cx-svg-icon-tone1 {
  fill: #98a7b8;
}
.cx-widget.cx-theme-irish-life .cx-input-group-btn .cx-svg-icon-tone1 {
  fill: #ffffff;
}
.cx-widget.cx-theme-irish-life label {
  color: #ffffff;
}
.cx-widget.cx-theme-irish-life a {
  color: #5463d6;
}
.cx-widget.cx-theme-irish-life a:hover {
  color: #615a80;
}
.cx-widget.cx-theme-irish-life .cx-transcript {
  background: #ffffff;
  color: #3a3160;
}
.cx-widget.cx-theme-irish-life .cx-webchat-chat-button {
  background-color: bg_color_12;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 6px;
  justify-content: space-around;
  align-items: center;
}
.cx-widget.cx-theme-irish-life .cx-widget.cx-webchat-chat-button > span {
  margin-bottom: 0;
}
.cx-widget.cx-theme-irish-life .cx-icon-alert-circle {
  color: #f53131;
}
.cx-widget.cx-theme-irish-life .cx-branding {
  color: #ffffff;
  background: #5261ac;
}
.cx-widget.cx-theme-irish-life .cx-branding * {
  fill: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-form-control {
  background-color: #ffffff;
  color: #3a3160;
}
.cx-widget.cx-theme-irish-life .cx-form-control.cx-error {
  border-color: #f53131 !important;
}
.cx-widget.cx-theme-irish-life .cx-form-control::placeholder {
  color: #c5ccd6;
}
.cx-widget.cx-theme-irish-life .cx-form-control:-moz-placeholder {
  color: #c5ccd6;
}
.cx-widget.cx-theme-irish-life .cx-form-control::-moz-placeholder {
  color: #c5ccd6;
}
.cx-widget.cx-theme-irish-life .cx-form-control:-ms-input-placeholder {
  color: #c5ccd6;
}
.cx-widget.cx-theme-irish-life .cx-form-control::-webkit-input-placeholder {
  color: #c5ccd6;
}
.cx-widget.cx-theme-irish-life input:focus,
.cx-widget.cx-theme-irish-life textarea:focus,
.cx-widget.cx-theme-irish-life .cx-btn:focus,
.cx-widget.cx-theme-irish-life .cx-button-group button:focus,
.cx-widget.cx-theme-irish-life .cx-form-control:not(.cx-error):focus {
  border-color: #75a8ff !important;
}
.cx-widget.cx-theme-irish-life input,
.cx-widget.cx-theme-irish-life select,
.cx-widget.cx-theme-irish-life textarea {
  background-color: #ffffff;
  color: #ffffff;
  border-color: #758384;
}
.cx-widget.cx-theme-irish-life .cx-btn-default,
.cx-widget.cx-theme-irish-life .cx-btn-primary {
  margin: 0.2rem 0;
  background: none;
  font-size: 1rem;
  font-weight: 400;
  border-color: #5261ac !important;
  color: #3a3160;
  background-color: transparent;
  border-radius: 9999px;
  min-width: 7rem;
  padding: 0.5rem;
}
.cx-widget.cx-theme-irish-life .cx-btn.cx-disabled {
  background: #cccccc;
}
.cx-widget.cx-theme-irish-life .cx-btn-primary {
  color: #ffffff;
  border-color: #5261ac !important;
  background: #5261ac;
  /* Old browsers */
  background: -moz-linear-gradient(top, #5261ac 0%, #5261ac 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5261ac 0%, #5261ac 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5261ac 0%, #5261ac 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4', endColorstr='@bg_color_5', GradientType=0);
  /* IE6-9 */
}
.cx-widget.cx-theme-irish-life .cx-button-group .cx-btn-primary:focus,
.cx-widget.cx-theme-irish-life .cx-btn-primary:focus {
  border-color: #dae6fc !important;
}
.cx-widget.cx-theme-irish-life .cx-ac-dropdown-menu {
  background: #5261ac;
  border-color: #5261ac !important;
}
.cx-widget.cx-theme-irish-life .cx-ac-suggestion:hover,
.cx-widget.cx-theme-irish-life .cx-ac-suggestion.cx-active {
  background-color: #75a8ff;
  color: #615a80;
}
.cx-widget.cx-theme-irish-life ::-webkit-scrollbar-thumb {
  background: #a3a8ae;
}
.cx-widget.cx-theme-irish-life .cx-smokescreen {
  background-color: #5261ac;
  opacity: 0.7;
}
.cx-widget.cx-theme-irish-life .cx-dialog-container .cx-dialog {
  background-color: #5261ac;
  border-color: #5261ac !important;
}
.cx-widget.cx-theme-irish-life .cx-theme-background {
  background-color: #5261ac;
}
.cx-widget.cx-theme-irish-life .cx-theme-border {
  border: 1px solid #5261ac !important;
}
.cx-widget.cx-theme-irish-life .cx-theme-text {
  color: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
  fill: #5081e1;
}
.cx-widget.cx-theme-irish-life .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
  fill: #4ac764;
}
.cx-widget.cx-theme-irish-life .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
  fill: #f53131;
}
.cx-widget.cx-theme-irish-life .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
  fill: #f8a740;
}
.cx-widget.cx-theme-irish-life .cx-warning {
  color: #f53131 !important;
}
.cx-widget.cx-theme-irish-life .cx-warning .cx-icon svg * {
  fill: #f53131 !important;
}
.cx-widget.cx-theme-irish-life .cx-countdown {
  color: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-countdown .cx-icon svg * {
  fill: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-title,
.cx-widget.cx-theme-irish-life .cx-titlebar,
.cx-widget.cx-theme-irish-life .cx-desktop {
  border-radius: 10px 10px 0px 0px;
}
.cx-widget.cx-theme-irish-life .cx-titlebar {
  padding: 1rem;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cx-widget.cx-theme-irish-life .cx-titlebar .cx-icon {
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 0.5rem;
}
.cx-widget.cx-theme-irish-life .cx-message-text {
  background-color: #5261ac;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 5px;
}
.cx-widget.cx-theme-irish-life .cx-input-container {
  background-color: #ffffff;
  color: #3a3160;
  border: none;
  border-top: 1px solid #5261ac;
  padding: 0.5rem !important;
}
.cx-widget.cx-theme-irish-life .cx-input-container .cx-textarea-cell {
  color: #3a3160;
  padding: 0.3rem;
  width: 100%;
  height: 52px;
  background-color: transparent;
}
.cx-widget.cx-theme-irish-life .cx-input-container .cx-textarea-cell textarea {
  width: 90% !important;
  height: 42px !important;
  background-color: transparent;
  height: auto !important;
  overflow-y: auto !important;
}
.cx-widget.cx-theme-irish-life .cx-input-container .cx-textarea-cell svg {
  stroke: #5081e1 !important;
  display: inline !important;
  stroke-width: 4px;
  width: 1rem;
  padding-top: 6px;
}
.cx-widget.cx-theme-irish-life .cx-input-container .cx-message-input {
  border: transparent !important;
  color: #3a3160;
}
.cx-widget.cx-theme-irish-life .cx-input-container .cx-message-input::placeholder {
  color: #615a80;
}
.cx-widget.cx-theme-irish-life .cx-message-input {
  background-color: white;
}
.cx-widget.cx-theme-irish-life .cx-webchat.cx-mobile .cx-input-container {
  padding: 1rem 0.5rem 0.5rem 0.5rem !important;
}
.cx-widget.cx-theme-irish-life .cx-footer {
  display: block;
  height: 58px;
  color: #3a3160;
  background-color: #ffffff;
}
.cx-widget.cx-theme-irish-life .cx-footer * {
  fill: #3a3160;
}
.cx-widget.cx-theme-irish-life .cx-form.cx-form-horizontal {
  background: #ffffff;
  color: #3a3160;
}
.cx-widget.cx-theme-irish-life .cx-form.cx-form-horizontal .cx-button-group {
  padding: 1rem 0;
}
.cx-widget.cx-theme-irish-life .cx-overlay {
  background: #ffffff;
  color: #3a3160;
  opacity: 100%;
  height: 100vh !important;
  font-size: 1rem;
  padding: 32px 24px 38px 24px;
}
.cx-widget.cx-theme-irish-life .cx-overlay .cx-notice {
  padding-bottom: 32px;
}
.cx-widget.cx-theme-irish-life .cx-webchat-chat-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cx-widget.cx-theme-irish-life .cx-webchat-chat-button .cx-btn-default {
  border: 1px solid;
}
.cx-widget.cx-theme-irish-life .cx-wrapper {
  background-color: #ffffff;
  margin: 0;
  padding: 1.5rem;
}
.cx-widget.cx-theme-irish-life .cx-wrapper p#cx_chat_confirm_close {
  color: #3a3160;
  padding: 1rem 0 2rem 0;
  text-align: center;
}
.cx-widget.cx-theme-irish-life table {
  color: #3a3160;
}
.cx-widget.cx-theme-irish-life table tr {
  height: 48px;
}
.cx-widget.cx-theme-irish-life .cx-input.cx-form-control {
  border: 1px solid #e9e9e9;
  color: #615a80;
  height: 36px;
}
.cx-widget.cx-theme-irish-life input.cx-input.cx-form-control::placeholder {
  color: #615a80;
}
.cx-widget.cx-theme-irish-life .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble {
  background-color: #5261ac;
  border: 1px solid #5261ac;
}
.cx-widget.cx-theme-irish-life .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble a {
  color: #f8a740;
}
.cx-widget.cx-theme-irish-life .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble span.cx-name,
.cx-widget.cx-theme-irish-life .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble span.cx-time {
  color: white;
}
.cx-widget.cx-theme-irish-life .cx-bubble-arrow svg > polygon {
  fill: #5261ac;
}
.cx-widget.cx-theme-irish-life .cx-menu-cell.cx-emoji-hasmenu {
  height: 0;
}
