@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&display=swap");

:root {
  --rt-opacity: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @media (hover: hover) {
    a:hover {
      @apply text-font-links-hovered;
    }
  }
  a:active {
    @apply text-menu-active;
  }
}

.CTA-banner {
  display: none;
}
.cx-widget-status {
  display: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
